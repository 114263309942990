<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 pb-0 v-title-cadastro">
      {{ titulo }}
    </v-card-title>
    <master-detail
      formTitle="Cadastro de NCM"
      :canDelete="!canView"
      :canEdit="!canView"
      :canView="canView"
      :cols="cols"
      :hasNewButton="!canView"
      :opts="opts"
      :resourceUrl="resourceUrl"
    ></master-detail>
  </v-card>
</template>

<script>
export default {
  components: {
    'master-detail': () => import("@/components/master-detail.vue"),
  },
  computed: {
    canView: function () {
      return this.$route.meta.viewMode === true;
    },
    client: function () {
      return this.getClient();
    },
    codigoProduto: function () {
      return this.$route.params.codigoProduto;
    },
    resourceUrl: function () {
      return `/v1/faturamento/ncm/${this.client.clientId}/produto/${this.codigoProduto}`;
    },
    titulo: function () {
      const title = this.nomeProduto || 'um produto';
      return `NCM de ${title}`;
    },
  },
  data: function () {
    return {
      nomeProduto: '',
      cols: [
        {
          key: "ncm",
          name: "NCM",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
          colSize: 12,
        },
        {
          key: "comentario",
          name: "Comentário",
          type: this.$fieldTypes.TEXTAREA,
          width: '50%',
          colSize: 12,
        },
        {
          key: "criado_em",
          name: "Criado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
      ],
      opts: {
        tecnologia_nacional: [
          {
            id: "Sim",
            nome: "Sim",
          },
          {
            id: "Nao",
            nome: "Não",
          },
        ],
        incentivo: [
          {
            id: "PPB",
            nome: "PPB",
          },
          {
            id: "TecNac",
            nome: "TecNac",
          },
        ],
      },
    };
  },
  mounted: function () {
    const nomeProduto = sessionStorage.getItem('nomeProduto');

    if (nomeProduto) {
      this.nomeProduto = nomeProduto;
      sessionStorage.removeItem('nomeProduto');
    }
  },
};
</script>
